<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
    >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="CampusDialogTitle"></span>
    </div>
    <!-- CampusData：{{CampusData}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="校区名称：" prop="name">
        <el-input v-model="formData.name" placeholder="请输入角色名称"></el-input>
      </el-form-item>
      <el-form-item label="实体校区：" prop="isPhysical">
        <el-select v-model="formData.isPhysical" placeholder="请选择是否为实体校区">
          <el-option
            v-for="item in isPhysicalArray"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="负责人：">
        <el-input v-model="formData.leader" placeholder="请输入校区负责人"></el-input>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    CampusData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      CampusDialogTitle: '',
      formData: {},
      rules: {
        name: [{ required: true, message: '请输入校区名称', trigger: 'blur' }],
        isPhysical: [{ required: true, message: '请选择是否为实体校区', trigger: 'blur' }]
      },
      isPhysicalArray: [
        {
          id:'1',
          title:'是'
        },
        {
          id:'2',
          title:'否'
        }
      ]
    }
  },
  methods: {
    openDialog () {
      this.formData = {}
      if (this.CampusData === null) {
        this.CampusDialogTitle = '新增校区'
      } else {
        this.CampusDialogTitle = '编辑校区'
        this.formData = Object.assign({}, this.CampusData)
      }
    },

    // 关闭
    close () {
      this.$emit('close')
    },
    saveRoleForm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.CampusData === null) {
            this.$emit('addCampus', this.formData)
          } else {
            let editData = {id: this.CampusData.id, ...this.formData}
            this.$emit('editCampus', editData)
          }
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../style/dialog.scss';
</style>
