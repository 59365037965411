var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("校区管理")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info" },
              on: { click: _vm.add },
            },
            [_vm._v("新增校区")]
          ),
        ],
        1
      ),
      _c(
        "el-table-draggable",
        { on: { drop: _vm.dropchange } },
        [
          _c(
            "el-table",
            {
              staticClass: "tb-list",
              attrs: {
                data: _vm.dataList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "校区名称", align: "center", prop: "name" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "400" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              type: "primary",
                              plain: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.manage(scope.row)
                              },
                            },
                          },
                          [_vm._v("成员管理")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "danger", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "danger", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.del(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-campus", {
        attrs: { show: _vm.dialogCampus, CampusData: _vm.CampusData },
        on: {
          close: function ($event) {
            _vm.dialogCampus = false
            _vm.CampusData = null
          },
          addCampus: _vm.addCampus,
          editCampus: _vm.editCampus,
        },
      }),
      _c("campus-manage", {
        attrs: { show: _vm.powerDialog, campusId: _vm.campusId },
        on: {
          close: function ($event) {
            _vm.powerDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }