<template>
  <el-row class="table">
    <span class="title">校区管理</span>
    <el-row class="row-bg">
      <el-button @click="add" type="info" class="tb-button">新增校区</el-button>
    </el-row>
    <el-table-draggable @drop="dropchange">
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
        class="tb-list"
      >
        <el-table-column label="校区名称" align="center" prop="name"></el-table-column>
        <el-table-column label="操作" align="center" width="400">
          <template slot-scope="scope">
            <el-button size="small" type="primary" plain @click="manage(scope.row)">成员管理</el-button>
            <el-button size="small" type="danger" plain @click="edit(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" plain @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
    <add-campus
      :show="dialogCampus"
      :CampusData="CampusData"
      @close="dialogCampus=false;CampusData=null"
      @addCampus="addCampus"
      @editCampus="editCampus"
    ></add-campus>

    <campus-manage
      :show="powerDialog"
      @close="powerDialog=false"
      :campusId="campusId"></campus-manage>
  </el-row>
</template>

<script>
import { getCampusAll, insertCampus, editCampus, delCampus,sortCampus } from '@/api/senate/campus'
import addCampus from '@/components/system/addCampus'
import campusManage from '@/components/system/campusManage'
import elTableDraggable from 'element-ui-el-table-draggable';

export default {
  components: { addCampus, campusManage,elTableDraggable },
  data () {
    return {
      dataList: [],
      dialogCampus: false,
      powerDialog: false,
      CampusData: null,
      campusId: '',
    }
  },
  created () {
    this.getCampusAll()
  },
  methods : {
    async dropchange(){
      var thembArray = []
      this.dataList.forEach(item => {
        thembArray.push({id:item.id})
      })
      const response = await sortCampus( JSON.stringify(thembArray))
      if(response && response.state === 'success') {
        window.$msg('排序成功')
      }
    },

    async getCampusAll () {
      const res = await getCampusAll()
      // console.log('res', res)
      this.dataList = res.body
    },
    add () {
      this.dialogCampus = true
    },

    edit (row) {
      this.CampusData = row
      this.dialogCampus = true
    },

    del (row) {
      this.$confirm('是否删除当前校区', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delCampus({
            campusId: row.id,
          })
          window.$msg('删除成功')
          this.getCampusAll()
        })
    },

    // 校区成员管理
    manage (row) {
      console.log('manage', row)
      this.campusId = row.id
      this.powerDialog = true
    },

    async addCampus (data) {
      await insertCampus({ ...data })
      window.$msg('新增成功')
      this.getCampusAll()
    },

    async editCampus (data) {
      await editCampus({
        id: data.id,
        isPhysical:parseInt(data.isPhysical),
        name: data.name,
        leader: data.leader ? data.leader : ''
      })
      window.$msg('编辑成功')
      this.getCampusAll()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
